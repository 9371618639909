<template>
  <div class="my-account-access">
    <HeroImage :bg-image="'images/2-service-pages-hero.jpg'" :has-purple-bg="false">
      <div class="hero-title">
        <h3 class="title is-3 has-text-centered pt-6 txt-white">
          Inicia sesión o registrate para seguir
        </h3>
      </div>
    </HeroImage>
    <section class="over-hero">
      <div class="container is-fullhd">
        <clinic-access></clinic-access>
      </div>
    </section>
    <Footer></Footer>
  </div>
</template>

<script>
import HeroImage from '@/components/HeroImage.vue'
import Footer from '@/components/Footer.vue'
import ClinicAccess from '@/components/Clinic/ClinicAccess.vue'

import { mapGetters } from 'vuex'

export default {
  name: 'ClinicLogin',
  components: {
    HeroImage,
    Footer,
    'clinic-access': ClinicAccess
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'user/isLoggedIn'
    })
  },
  methods: {
    goTo (path) {
      this.$router.push({ path: path })
    }
  },
  created () {
    if (this.isLoggedIn) {
      this.goTo('/clinic/personal-informations')
    }
  }
}
</script>

<style scoped lang="scss">
.my-account-access{
  width: 100%;
  section{
    width: 100%;
    padding: 0px 0px;
    &.over-hero{
      position: relative;
      margin-top: -340px;
      margin-bottom: 80px;
      z-index: 100;
    }
  }
}
</style>
